
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";

export default defineComponent({
  name: "customer-financial-statements",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Resolutions List", [
        "Customer",
        "Financial Resolutions",
      ]);
    });

    return {};
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    return {
      store,
      cstmr_id,
      finResolutions: [],
    };
  },
  methods: {
    init() {
      this.store
        .dispatch(Actions.GET_FINANCIAL_RESOLUTIONS, this.cstmr_id)
        .then(() => {
          this.finResolutions = this.store.getters.getFinancialResolutionsData.out;
        });
    },
    getHumanDate(date) {
      console.log(date);
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
  },
  async mounted() {
    this.init();
  },
});
